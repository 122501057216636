.App {
  text-align: center;
}

.App-logo {
  border-radius: 50%;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - var(--vh-fix, 0px) - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.icon {
  animation: pulse 2s ease-in-out;
  animation-iteration-count: infinite;

  height: 55px;
  background-color: yellowgreen;
  padding: 5px 10px;
  border-radius: 5px;
}

h1 + p {
  margin-top: 0.67em;
}
p > a {
  margin: 16px;
}
footer {
    height: 20px;
    padding: 10px;
    background-color: #13151d;
    color: white;
}
footer > a {
    text-decoration: none;
    color: inherit;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(154, 205, 50, 0.61);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(154, 205, 50, 0.61);
    box-shadow: 0 0 0 0 rgba(154, 205, 50, 0.61);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(154, 205, 50, 0);
    box-shadow: 0 0 0 10px rgba(154, 205, 50, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(154, 205, 50, 0);
    box-shadow: 0 0 0 0 rgba(154, 205, 50, 0);
  }
}
